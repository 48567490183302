import React, { useEffect, useState } from "react";

type Props = {
    status: boolean | undefined;
};

export default function CategoryList(props: Props): JSX.Element {
    const [show, setShow] = useState(true);

    // On componentDidMount set the timer
    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            setShow(false)
        }, 5000)

        return () => {
            clearTimeout(timeId)
        }
    }, []);

    // If show is false the component will return null and stop here
    if (!show && props.status) {
        return <></>;
    }

    if (props.status) {
        return (
            <div className="fixed bottom-4 right-4 mb-4 mr-2"><span className="bg-green-400 p-4 rounded-full">Connected</span></div>
        );
    }

    return (
        <div className="fixed bottom-4 right-4 mb-4 mr-2 animate-pulse"><span className="bg-red-400 p-4 rounded-full text-red-900">Disconnected</span> </div>
    );

}
