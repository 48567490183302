import PodcastPlayer from "./components/PodcastPlayer";
import AppRoutes from "./Routes";

function App() {
    return (
        <div className="h-full flex flex-col">
            <AppRoutes />
            <div className="mt-auto">
                <PodcastPlayer />
            </div>
        </div>
    );
}

export default App;