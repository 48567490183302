import { Link } from "react-router-dom";
import PodcastPlayer from "./PodcastPlayer";

export default function CategoryList({
    children,
}: {
    children: React.ReactNode
}): JSX.Element {
    return (
        <div className="flex-grow flex flex-col">
            <nav className="bg-ap24">
                <div className="max-w-7xl mx-auto md:px-4">
                    <div className="flex flex-row gap-4 p-4 items-center text-white">
                        <Link to="/"><img src="/ap24logo.png" title="Autopflege24" alt="Autopflege24" width="256" height="110" /></Link>
                        <div>
                            <h1 className="text-sm md:text-4xl">Stadt, Land, Autopflege</h1>
                            <span className="text-xs md:text-xl">Für alle mit dem richtigen Nagel im Kopf.</span>
                        </div>
                    </div>
                </div>
            </nav>
            <main className="flex-grow mx-4 md:mx-0">
                {children}
            </main>
        </div>
    );
}