import { Link } from "react-router-dom";
import Layout from "../../components/Layout";

const About = () => {

    return (
        <Layout>
            <section className="container my-8 mx-auto">
                <Link to="/" className="rounded-full bg-ap24 text-white py-4 px-8">Zur Startseite</Link>
            </section>

            <section className="container p-4 mx-auto bg-white rounded-xl">

                <h2 className="font-bold">Was ist das hier?</h2>
                <p>Sicher hast du es schon erraten: Es geht auf dieser Seite darum, den klassiker Stadt, Land, Fluss zu spielen. Jedoch mit anderen Kategorien als gewöhnlich.</p>
                <p>Stadt, Land, Autopflege entstand aus einer einfachen Laune heraus. Als Hörer des Autopflege24-Podcasts &quot;Detailing-Gebabbel&quot; musste man einfach darüber stolpern. Alle Hörer des Podcasts wissen daher wahrscheinlich schon bei Besuch der Seite worum es hier geht.</p>
                <p>Wer noch immer auf dem Schlauch steht, dem sei ganz kräftig der Podcast ans Herz gelegt. In der Folge &quot;<a href="https://open.spotify.com/episode/7LqrkY9pSC4tSCCfTOulQZ?si=97bbaed567014426">Die Autofplege Gameshow: Stadt, Land, Autopflege</a>&quot; werdet ihr erstmals über das Spiel, um welches auf dieser Seite geht finden.</p>

                <h2 className="font-bold mt-4">Wer steckt hier dahinter?</h2>
                <p>Das Spiel ist nicht von Autopflege24 entwickelt und wird auch nicht bezahlt oder sonst wie unterstützt. Das Spiel wurde von mir, Lucas Kurz, aus einer einfachen Laune heraus entwickelt.</p>

            </section>
        </Layout>
    );
};

export default About;