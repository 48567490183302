import gameSlice from './gameSlice';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import axios from 'axios';


export const gameActions = gameSlice.actions;

export const changeRoomId = (roomId: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState) => {
        dispatch(gameActions.changeRoomId(roomId));
    }
}

export const changeUsername = (username: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState) => {
        dispatch(gameActions.changeUsername(username));
    }
}

export const setCurrentResult = (category: string, value: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState) => {
        dispatch(gameActions.setCurrentResult({ category, value }));
    }
}

export const clearCurrentResult = ():ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState) => {
        dispatch(gameActions.clearCurrentResult());
    }
}

export const refreshGameState = (roomId: string):ThunkAction<void,RootState,unknown,AnyAction>=>{
    return async(dispatch,getState) => {
        axios.get( ( process.env.REACT_APP_API_URL || '/' ) + `api/sessions/${roomId}`).then((v) => {
            dispatch(gameActions.refreshGameState( v.data ));
        });
    }
}