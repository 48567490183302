import React from 'react';
import ReactDOM from 'react-dom/client';
import { io } from "socket.io-client";
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SocketContext from './contexts/Socket';
import { Provider } from 'react-redux';
import store from './store';

const mainSocket = io(process.env.REACT_APP_SERVER_URL, {
  autoConnect: false,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <SocketContext.Provider value={{ mainSocket }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SocketContext.Provider>
  </Provider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
