import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CategoryResult, GameModel, GameState } from '../types';

const initialState: GameModel = {
    roomId: '',
    username: loadUsername(),
    gameState: {
        currentLetter: '-',
        usedLetters: [],
        categories: [],
        results: [],
        status: '',
    },
    userResults: {},
}

function loadUsername(): string {
    const username = localStorage.getItem("username");
    
    if (username === null) return generateName();

    return JSON.parse(username);
}

function generateName() {
    var wordList = ['NagelImKopf', 'Autopfleger24', 'Wascheimer', 'DropneticFanatic', 'DetailingOutlaw', 'Fluffy', 'NichtChristophVonSonax', 'EintrachtAdler'];
    var chooseWord = wordList[Math.floor(Math.random()*wordList.length)];
    var randomNumber = Math.floor((Math.random() * 1000) + 1);
    return chooseWord + '_' + randomNumber;
}

const gameSlice = createSlice({
    name: 'game',

    initialState: initialState,
    reducers: {
        refreshGameState(state, action: PayloadAction<GameState>) {
            state.gameState = action.payload;
        },

        changeRoomId(state, action: PayloadAction<string>) {
            state.roomId = action.payload;
        },

        changeUsername(state, action: PayloadAction<string>) {
            state.username = action.payload;
        },

        setCurrentResult(state, action: PayloadAction<CategoryResult>) {
            const data = {
                ...state.userResults,
                [action.payload.category]: action.payload.value
            };
            
            state.userResults = data;
        },

        clearCurrentResult(state) {
            state.userResults = {};
        }
    }
})

export default gameSlice;