import React from "react";

const NotFound = () => {
    return <div className="h-full m-auto flex flex-col justify-center items-center">
        <div className="h-52 w-52 md:h-[25rem] md:w-[25rem] font-bold text-gray-700 rounded-full bg-white flex flex-col text-center items-center justify-center font-mono">
            <svg className="h-40 mx-auto" version="1.1" id="Layer_1" x="0px" y="0px"
                viewBox="0 0 290.626 290.626">
                <g>
                    <g>
                        <g>
                            <path d="M276.328,93.75h14.297v-37.5h-18.989c-2.395-28.823-26.578-51.563-56.011-51.563c-25.003,0-39.028,15.295-44.784,23.573
				c-0.698-0.042-1.383-0.136-2.091-0.136c-13.177,0-24.534,7.823-29.747,19.055C132.741,20.194,108.553,0,79.688,0
				C47.668,0,21.394,24.839,18.99,56.25H0v37.5h14.18c1.134,22.917,10.603,43.631,25.345,59.344l12.037,114.361
				c0.202,13.069,15.206,23.17,28.125,23.17h131.25c12.919,0,27.923-10.102,28.125-23.17L251.1,153.094
				C265.837,137.391,275.184,116.658,276.328,93.75z M252.9,135.97l4.444-42.22h9.605C266.091,109.303,261.094,123.769,252.9,135.97
				z M168.75,37.5c8.123,0,15.005,4.163,19.378,11.723l8.114-4.697c-3.773-6.525-9.117-11.306-15.389-13.973
				c5.77-7.003,16.875-16.491,34.772-16.491c24.267,0,44.278,18.534,46.636,42.188H145.786C147.966,45.568,157.434,37.5,168.75,37.5
				z M281.25,65.624v18.751H112.5v-4.688c0-7.753,6.309-14.063,14.063-14.063H281.25z M79.688,9.375
				c26.85,0,48.947,20.634,51.323,46.875h-4.448c-12.923,0-23.438,10.514-23.438,23.438v42.188c0,7.753-6.309,14.063-14.063,14.063
				c-7.754,0-14.062-6.311-14.062-14.064V56.25H28.364C30.741,30.009,52.838,9.375,79.688,9.375z M23.564,93.75h9.717l4.43,42.061
				C29.555,123.628,24.422,109.266,23.564,93.75z M9.375,84.375v-18.75h56.25v18.75H9.375z M229.711,266.701l-0.023,0.487
				c0,7.242-10.561,14.063-18.75,14.063H79.688c-8.189,0-18.75-6.82-18.75-14.063L49.959,162.769
				c14.789,11.634,33.173,18.816,53.166,19.814v9.605H187.5v-9.614c20.006-0.998,38.367-8.236,53.161-19.889L229.711,266.701z
				 M112.5,182.813v-9.375h65.625v9.375H112.5z M242.1,149.011c-14.208,14.048-33.375,23.016-54.6,24.188v-9.136h-84.375v9.145
				c-21.248-1.177-40.406-10.195-54.619-24.272L42.708,93.75h22.917v28.125c0,12.923,10.514,23.438,23.438,23.438
				s23.438-10.514,23.438-23.438V93.75h135.417L242.1,149.011z"/>
                            <path d="M267.188,220.313c-12.923,0-23.438,10.514-23.438,23.438s10.514,23.438,23.438,23.438s23.438-10.514,23.438-23.438
				S280.111,220.313,267.188,220.313z M267.188,257.813c-7.753,0-14.063-6.309-14.063-14.063c0-7.754,6.309-14.063,14.063-14.063
				c7.754,0,14.063,6.309,14.063,14.063C281.251,251.504,274.941,257.813,267.188,257.813z"/>
                            <rect x="75" y="262.5" width="9.375" height="9.375" />
                            <rect x="93.75" y="262.5" width="9.375" height="9.375" />
                            <rect x="206.25" y="262.5" width="9.375" height="9.375" />
                            <path d="M173.437,220.313c0,7.754,6.309,14.063,14.063,14.063c7.754,0,14.063-6.309,14.063-14.063
				c0-7.754-6.31-14.063-14.063-14.063S173.437,212.56,173.437,220.313z M192.188,220.313c0,2.583-2.105,4.688-4.688,4.688
				s-4.688-2.105-4.688-4.688s2.105-4.688,4.688-4.688S192.188,217.729,192.188,220.313z"/>
                        </g>
                    </g>
                </g>
            </svg>

            <p className="text-xl md:text-4xl my-4">Hier gibt es nichts zu sehen.</p>
        </div>
    </div>;
};

export default NotFound;