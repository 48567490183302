import React from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { setCurrentResult } from "../store/gameActions";
import { GameState } from "../types";

type Props = Pick<GameState, "categories">;

export default function CategoryList(props: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const userResults = useAppSelector(state => state.game.userResults);

  const handleValueChange = (event: any) => {
    dispatch(setCurrentResult(event.target.name, event.target.value));
  };

  return (
    <ol className="">
      {props.categories.map((category, index) => (
        <li key={index} className="flex flex-row gap-4 items-center mb-4" >
          <label className="min-w-[6rem] font-bold">{category}</label>
          <input
            className="flex-grow mt-1 block w-full rounded-md bg-gray-200 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            name={category}
            value={userResults[category]}
            onChange={handleValueChange}
            type="text" />
        </li>
      ))}
    </ol>
  );
}
