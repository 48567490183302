import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Gameroom from "./pages/Game/Gameroom";
import Imprint from "./pages/Imprint/Imprint";
import About from "./pages/About/About";

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/:roomId" element={<Gameroom />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}