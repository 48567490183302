import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useAppDispatch, useAppSelector } from "../../store";
import { changeRoomId, changeUsername } from "../../store/gameActions";

const Home = () => {
    const dispatch = useAppDispatch();
    const roomId = useAppSelector(state => state.game.roomId);

    const [username, setUsername] = useLocalStorage("username", '');

    const handleRoomNameChange = (event: any) => {
        dispatch(changeRoomId(event.target.value));
    };

    const handleUsernameChange = (event: any) => {
        setUsername(event.target.value);
        dispatch(changeUsername(event.target.value));
    };

    return (
        <Layout>
            <section className="container pt-8 mx-auto max-w-md ">
                <div className="bg-white flex flex-col gap-4 p-8 rounded-xl">
                    <h2 className="text-center font-bold text-xl">Willkommen zu Stadt, Land, Autopflege!</h2>
                    <input
                        type="text"
                        placeholder="Spielraum"
                        value={roomId}
                        onChange={handleRoomNameChange}
                        className="rounded-xl bg-gray-200 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    />
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={handleUsernameChange}
                        className="rounded-xl bg-gray-200 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    />
                    <Link to={`/${roomId}`} className="bg-ap24 text-white p-2 text-center rounded-full w-full">
                        Raum beitreten
                    </Link>
                </div>
            </section>

            <section className="container pt-4 mx-auto max-w-md flex justify-center gap-4">
                <Link to="/about" className="rounded-full bg-gray-400 text-white p-2 px-8">Über SLAP</Link>
                <Link to="/imprint" className="rounded-full bg-gray-400 text-white p-2 px-8">Impressum</Link>
            </section>

            <footer className="mx-auto max-w-md text-center mt-4">
                <span>Erstellt von Lucas Kurz</span>
            </footer>
        </Layout>
    );
};

export default Home;