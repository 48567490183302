// src/app/store.ts
import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import gameSlice from './gameSlice'

const store = configureStore({
    reducer: {
      game: gameSlice.reducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch =typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppThunk = ThunkAction<void, RootState, unknown, Action>

export default store