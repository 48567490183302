import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import CategoryList from "../../components/CategoryList";
import { Message, ResultModel } from "../../types";
import useSockets from "../../hooks/useSockets";
import ConnectionStatus from "../../components/ConnectionStatus";

import { useAppDispatch, useAppSelector } from "../../store";
import { clearCurrentResult, refreshGameState } from "../../store/gameActions";
import axios from "axios";
import Layout from "../../components/Layout";

type User = {
    id: string,
    name: string,
    room: string;
}

export default function Gameroom() {

    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState<Array<Message>>([]);

    const setRef = useCallback((node: any) => {
        if (node) {
            node.scrollIntoView({ smooth: true, })
        }
    }, [])

    const sockets = useSockets();
    const socket = sockets?.mainSocket;

    const params = useParams();

    const messagesEndRef = useRef<HTMLDivElement>(null)

    const dispatch = useAppDispatch();
    const username = useAppSelector(state => state.game.username);
    const gameState = useAppSelector(state => state.game.gameState);
    const userResults = useAppSelector(state => state.game.userResults);

    useEffect(() => {
        socket?.connect();

        if (params.roomId)
            dispatch(refreshGameState(params.roomId));

        socket?.emit("join", { room: params.roomId, username }, (error: any) => {
            if (error) {
                alert(error);
            }
        });

        // Destroys the socket reference
        // when the connection is closed
        return () => {
            socket?.disconnect();
        };
    }, [socket, params.roomId]);

    useEffect(() => {
        socket?.on("message", (message) => {
            setMessages((oldMessages) => oldMessages.concat(message));
        });

        socket?.on("roomData", ({ users }) => {
            setUsers(users);
        });

        socket?.on("refresh-resultlist", () => {
            if (params.roomId)
                dispatch(refreshGameState(params.roomId));
        });

        return () => {
            socket?.off("message");
            socket?.off("roomData");
            socket?.off("refresh-resultlist");
        };
    }, [socket]);

    useEffect(() => {
        socket?.on("stop-round", () => {
            sendResult();
        });

        socket?.on("start-round", () => {
            if (params.roomId) {
                console.log('Refresh for new round');
                dispatch(refreshGameState(params.roomId));
                dispatch(clearCurrentResult());
            }
        });

        return () => {
            socket?.off("stop-round");
            socket?.off("next-round");
        };
    }, [socket, userResults]);

    // Function for sending current results on end of round
    const sendResult = () => {
        console.log(userResults);

        // Send results to server
        axios.post((process.env.REACT_APP_API_URL || '/') + `api/sessions/${params.roomId}/${username}`, userResults).then((v) => {
            socket?.emit('result-pushed');
        })
    }

    //Function for Sending Message
    const sendMessage = (e: React.UIEvent) => {
        e.preventDefault();

        if (message) {
            socket?.emit('sendMessage', message, () => setMessage(''))
        }
    }

    //Function for stopping round
    const stopRound = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        socket?.emit('stop-round');
    }

    // Function for starting next round
    const startNextRound = (e: React.UIEvent) => {
        e.preventDefault();

        socket?.emit('next-round');
    }

    return (
        <Layout>
            <div className="my-4">
                <ConnectionStatus status={socket?.connected} />

                <div className="container flex flex-col md:grid md:grid-cols-[80%_20%] gap-4 justify-center mx-auto mt-8 h-full">
                    <div className="flex flex-col order-2 md:order-first">
                        <div className="flex flex-col bg-white rounded-xl">
                            <h2 className="font-bold text-xl ml-4 my-2">Spielfeld</h2>
                            <hr />
                            {gameState != null && (gameState.status === 'INITIALIZED' || gameState.status === 'START_OF_ROUND') && (
                                <form onSubmit={stopRound}>
                                    <div className="m-4">
                                        <CategoryList categories={gameState.categories} />
                                    </div>
                                    <div id="game-buttons" className="flex flex-row justify-end m-4">
                                        <button className="bg-rose-500 text-white p-2 mt-2 rounded-full px-8" type="submit">
                                            STOPP
                                        </button>
                                    </div>
                                </form>
                            )}
                            {gameState.status === 'END_OF_ROUND' && (
                                <div>
                                    <div className="m-4">
                                        <table className="w-full text-left rounded overflow-hidden">
                                            <thead className="bg-ap24 text-white">
                                                <th className="p-2">User</th>
                                                {gameState.categories.map((category: string, index) => {
                                                    return <th>{category}</th>
                                                })}
                                            </thead>
                                            <tbody>
                                                {gameState.results.map((result: any, index) => {
                                                    if (result.letter !== gameState.currentLetter) return <></>;
                                                    return <tr className={index % 2 === 0 ? 'bg-slate-100' : 'bg-slate-200'}>
                                                        <td className="p-2">{result.user}</td>
                                                        <>{gameState.categories.map((category: string, index) => {
                                                            return <td>{result.result[category]}</td>
                                                        })}</>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id="game-buttons" className="flex flex-row justify-end m-4">
                                        <button className="bg-green-500 text-white p-2 mt-2 rounded-full px-8" onClick={event => startNextRound(event)}>
                                            Nächste Runde starten
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col order-3 md:order-2 md:col-span-2">
                        <div className="container flex flex-row gap-4 justify-center mx-auto">
                            <div className="w-full bg-white rounded-xl overflow-hidden">
                                <h2 className="font-bold text-xl ml-4 my-2">Chat</h2>
                                <hr />
                                <div id="chat-messages" className="p-2 max-h-64 overflow-y-auto">
                                    {messages.map((msg: Message, index) => {
                                        const lastMessage = messages.length - 1 === index

                                        if (msg.user === 'AP24') {
                                            return (
                                                <div ref={lastMessage ? setRef : null} key={index} className="my-1 flex flex-col items-center" >
                                                    <div className="inline-block font-thin text-sm my-2">
                                                        <p className="break-words">{msg.text}</p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        else if (msg.user !== username) {
                                            return (
                                                <div ref={lastMessage ? setRef : null} key={index} className="my-1 flex flex-col items-start" >
                                                    <div className="inline-block p-1 px-2 rounded-md bg-cyan-200">
                                                        <p className="break-words">{msg.text}</p>
                                                    </div>
                                                    <p className="text-xs">{msg.user}</p>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div ref={lastMessage ? setRef : null} key={index} className="my-1 flex flex-col items-end" >
                                                    <div className="inline-block p-1 px-2 rounded-md text-white bg-ap24">
                                                        <p className="break-words">{msg.text}</p>
                                                    </div>
                                                    <p className="text-xs">{msg.user}</p>
                                                </div>
                                            );
                                        }

                                    })}
                                    <div ref={messagesEndRef} />
                                </div>

                                <form className="flex h-12">
                                    <input
                                        className="flex-grow p-2 bg-gray-200 border-transparent focus:ring-transparent focus:shadow-none focus:border-transparent focus:outline-none"
                                        type="text"
                                        placeholder="Schreibe eine Nachricht..."
                                        value={message}
                                        onChange={({ target: { value } }) => setMessage(value)}
                                        onKeyDown={event => event.key === 'Enter'
                                            ? sendMessage(event) : null}
                                    />
                                    <button className="bg-ap24 text-white px-4"
                                        onClick={event => sendMessage(event)}>Senden</button>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="order-1">
                        <div className="flex flex-col gap-4">
                            <div id="game-menu" className="bg-white rounded-xl">
                                <div className="p-4">
                                    <h3 className="font-bold text-center">
                                        Buchstabe:<br />
                                        <span className="text-xl md:text-8xl">{gameState?.currentLetter || '-'}</span>
                                    </h3>
                                </div>
                            </div>
                            <div id="game-menu" className="bg-white rounded-xl hidden md:block">
                                <div className="p-4">
                                    <h3 className="font-bold text-center">Spieler:</h3>
                                    <ul>
                                        {users.map((user: User) => {
                                            return (
                                                <li key={user.id}>{user.name}</li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <Link to="/" className="hidden md:block bg-rose-500 text-white p-2 rounded-full text-center">Spiel verlassen</Link>
                        </div>
                    </div>
                    <div className="order-last md:order-none block md:hidden">
                        <div className="flex flex-col gap-4">
                            <div id="game-menu-mobile" className="bg-white rounded-xl">
                                <div className="p-4">
                                    <h3 className="font-bold text-center">Spieler:</h3>
                                    <ul>
                                        {users.map((user: User) => {
                                            return (
                                                <li key={user.id}>{user.name}</li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <Link to="/" className="bg-rose-500 text-white p-2 rounded-full text-center">Spiel verlassen</Link>
                        </div>
                    </div>
                </div>
            </div >
        </Layout>
    );
}