import React, { Fragment, useEffect, useState } from "react";

export default function PodcastPlayer(): JSX.Element {

    const playerSource = "https://cdn.podigee.com/podcast-player/javascripts/podigee-podcast-player.js";
    const podigeeThemeUrl = process.env.REACT_APP_PODIGEE_THEME_BASE;

    const [isLoading, setIsLoading] = useState(false);

    const [playerConfiguration, setPlayerConfiguration] = useState({
        episode: {},
        podcast: {
            feed: "https://autopflege24.podigee.io/feed/mp3",
            title: "Detailing Gebabbel - der Autopflege Podcast",
            subtitle: null,
            description:
                'Detailing Gebabbel - der Podcast für ambitionierte Autopflege Fans und Fahrzeugaufbereiter!\n\nSeit über 15 Jahren betreiben wir im schönen Hessen unter Autopflege24 einen Onlineshop für hochwertige Autopflegemittel. In den letzten Jahren haben wir dazu noch einem YouTube Kanal etabliert, auf welchem wir Einsteigern und Fortgeschrittenen das Thema "Beyond Car Care - Mehr als Autopflege" näherbringen. \n\nMit diesem Podcast wollen wir unser Angebot abrunden und Euch regelmäßig mit spannenden Geschichten rund um die Autopflege und deren Dunstkreis versorgen. Zukünftig sollen Gäste mit uns babbeln, wir plaudern hier und da aus dem Nähkasten unseres Arbeitsalltags und haben hoffentlich für Euch eine angenehme Unterhaltung parat.\n\nWir freuen uns auf Euch!\n\nGepflegte Grüße aus Hessen, Euer Autopflege24 Team',
            coverUrl:
                "https://images.podigee-cdn.net/400x,s8FP1NJuM4A0WGJX0ms5DvHpiAmGyjVZExDkR-FYWuDg=/https://cdn.podigee.com/uploads/u11810/6913f6c8-1a10-43cf-afed-39d3d21d2cf2.jpg",
            embedCode:
                '\u003cscript class="podigee-podcast-player"\n src="https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js"\n data-configuration="https://autopflege24.podigee.io/embed?context=external"\u003e\u003c/script\u003e',
            episodes:
                "https://autopflege24.podigee.io/embed/playlist?context=website",
            url: "https://autopflege24.podigee.io/",
            connections: {
                itunes: "https://podcasts.apple.com/podcast/id1500382161",
                spotify: "https://open.spotify.com/show/4OddqS6VfCB6wFk5a2hwR2",
                deezer: "https://www.deezer.com/show/894122",
                google:
                    "https://podcasts.google.com/subscribe-by-rss-feed?feed=aHR0cHM6Ly9hdXRvcGZsZWdlMjQucG9kaWdlZS5pby9mZWVkL21wMw%3D%3D",
                podimo:
                    "https://share.podimo.com/podcast/1210c3b8-77bb-4512-ab05-147360defb2c",
            },
            language: "de",
        },
        extensions: {
            ChapterMarks: { disabled: false },
            Download: { disabled: false },
            EpisodeInfo: { disabled: false },
            Playlist: { disabled: false },
            Share: { disabled: false },
            Transcript: { disabled: false },
            SubscribeBar: { disabled: false },
        },
        options: {
            theme: {
                html: `${podigeeThemeUrl}/podigee-theme.html`,
                css: `${podigeeThemeUrl}/podigee-theme.css`,
            },
            startPanel: null,
            whitelabelEnabled: false,
            webplayer_height: 142,
            themeVersion: 1,
            themeCss: null,
            themeHtml: null,
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const res = await fetch("https://autopflege24.podigee.io/embed/playlist?context=external&source=webplayer&page_size=10&offset=0", {
                    "method": "GET"
                });
                const data = await res.json();


                setPlayerConfiguration((oldConfig) => {
                    oldConfig['podcast']['episodes'] = data['episodes'];
                    oldConfig['episode'] = data['episodes'][0];
                    console.log(oldConfig);
                    return oldConfig;
                });
            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        };

        fetchData();
    }, []);

    const script = document.createElement("script");
    script.src = playerSource;
    script.async = true;
    document.body.appendChild(script);

    return (
        <Fragment>
            {isLoading ? (
                <div>Loading ...</div>
            ) : (
                <div id="player">
                    <script
                        className="podigee-podcast-player"
                        src={playerSource}
                        data-configuration={JSON.stringify(playerConfiguration)} />
                </div>
            )}
        </Fragment>
    );
}