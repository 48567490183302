import { Link } from "react-router-dom";
import Layout from "../../components/Layout";

const Imprint = () => {

    return (
        <Layout>
            <section className="container my-8 mx-auto">
                <Link to="/" className="rounded-full bg-ap24 text-white py-4 px-8">Zur Startseite</Link>
            </section>

            <section className="container p-4 mx-auto bg-white rounded-xl">

                <h3 className="font-bold">Angaben gemäß § 5 TMG:</h3>
                Lucas Kurz<br />
                Hochstrasse 18<br />
                65624 Altendiez<br />
                <br />
                <p>Die Seite wird nicht von Autopflege24 betrieben oder unterstützt.</p>

                <h3 className="font-bold mt-4">Kontakt</h3>
                Telefon: +4917620244212<br />
                E-Mail: lucas@nur-kurz.de<br />

                <h3 className="font-bold mt-4">Haftung für Inhalte</h3>
                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                    eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                    verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                    gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen.
                </p>
                <p>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
                    nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                    diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                    einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                    entfernen.
                </p>

                <h3 className="font-bold mt-4">Haftung für Links</h3>
                <p>
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren
                    Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
                    fremden Inhalte auch keine Gewähr übernehmen. Für die
                    Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                    Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                    Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                    überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                    Verlinkung nicht erkennbar.
                </p>
                <p>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
                    ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                    entfernen.
                </p>

                <h3 className="font-bold mt-4">Urheberrecht</h3>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
                    Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
                    Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                    jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
                    nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                </p>
                <p>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                    werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                    Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
                    wir derartige Inhalte umgehend entfernen.
                </p>
            </section>
        </Layout>
    );
};

export default Imprint;